export const matchingNoticeList = {
  notice: 'お知らせ',
  notice17: {
    title:
      '株式会社M&Aナビ、第一次事業再構築補助金対象企業に採択。国から1億円の支援を受け、後継者不足問題を解決する事業承継プラットフォーム構築へ。',
    description:
      '完全無料のM&Aマッチングプラットフォーム「M&Aナビ」を運営する株式会社M&Aナビ（本社：東京都渋谷区、代表取締役：瀧田雄介）は、この度、経済産業省が提供する事業再構築補助金（※1）において「卒業枠（※2）」で採択されましたことをお知らせさせていただきます。',
  },
  notice16: {
    title: 'M&A、事業承継のマッチングメディアM&Aナビを『完全無料化』',
    description:
      '「M&Aナビ」の開発・運営事業を⾏う、株式会社M&Aナビ（本社：東京都渋谷区、代表取締役：瀧⽥ 雄介、以下M&Aナビ）は、本⽇3⽉15⽇よりサービス利⽤料および成約料を完全無料化いたしました。',
  },
  notice15: {
    title: '株式会社M&Aナビへの商号変更および新経営体制のお知らせ（旧：株式会社ALIVAL）',
    description:
      '株式会社ALIVAL（本社：東京都港区）は、2021年1月29日に商号を「株式会社M&Aナビ」に変更し、新経営体制に移行したことをお知らせいたします。また、新体制にともない本店を東京都渋谷区に移転いたしました。',
  },
  notice14: {
    title:
      '若手ビジネスマンがみずから買収先を探してオーナーになれる「リノベーション・スタートアップ・ファンド」の経営者候補の登録受付を開始',
    description:
      'このたび、株式会社ALIVALは、2020年6月18日より後継者不足問題に悩んでいる企業のオーナー経営者となる候補者の登録受付を開始したことをおしらせします。',
  },
  notice13: {
    title: 'あらゆる経営課題を解決する「LaunchLite（ローンチライト）」リリースのお知らせ',
    description:
      'この度、M&A支援を通じて得た独自ノウハウを活かし、あらゆる経営課題を解決する「LaunchLite（ローンチライト）」のリリースをお知らせします。',
  },
  notice12: {
    title: '中小企業M&A専門のセカンドオピニオンサービス開始のお知らせ',
    description:
      'この度、これまでの豊富な成約実績やデータベースを元にし、M&Aに関するあらゆるご相談を承る「セカンドオピニオンサービス」を開始いたしましたのでお知らせします。',
  },
  notice11: {
    title: '【GW中も利用可能】M&Aオンライン無料相談サービスを開始しました',
    description: 'この度、完全無料のオンライン相談サービスのご提供を開始したことをお知らせします。',
  },
  notice10: {
    title: 'M&Aサポートプログラム開始のお知らせ',
    description:
      'この度、M&Aナビが誇る経験豊富なアドバイザー陣が、買収を検討している方をオーダーメイドで支援する「M&Aサポートプログラム」を開始いたしましたのでお知らせします。',
  },
  notice9: {
    title: 'M&Aナビ プレミアム公開のお知らせ',
    description:
      'この度、”売上が1億円以上の経営者様向け”のM&A・事業承継を徹底サポートさせていただく、「M&Aナビ プレミアム」を公開しましたのでお知らせします。',
  },
  notice8: {
    title: 'M&Aナビ「マイページ」アップデートのお知らせ',
    description:
      'この度、M&Aナビをより快適にご利用いただくために、2020年3月1日にマイページをアップデートすることをお知らせいたします。',
  },
  notice7: {
    title: '日経ムック まるわかり！M&A」にて、M&Aナビが紹介されました',
    description:
      '2020/2/26発売の「日経ムック まるわかり！M&A」にて、弊社運営のM&Aナビと、M&Aナビが実施した調査データが紹介されました。',
  },
  notice6: {
    title: '株式会エス・エム・エス様と共同で介護業界M&Aに関するセミナーを開催します',
    description:
      'このたび、東証一部上場企業で介護業界のリーディングカンパニーである株式会社エス・エム・エス様と共同で、介護業界へのM&Aによる新規参入についてのセミナーを行います。',
  },
  notice5: {
    title: '「個人での会社買収の意向調査」を実施いたしました',
    description:
      '小規模M&Aに特化したプラットフォーム「M&Aナビ」による「個人での会社買収の意向」の調査結果です。転職でも企業でもない新たな選択肢としてM&Aを考えているビジネスマンが多い結果となりました。',
  },
  notice4: {
    title: 'M&Aナビ リニューアルのお知らせ',
    description: 'この度、M&Aナビをより快適にご利用いただけるようにリニューアルを実施いたしましたのでお知らせします。',
  },
  notice3: {
    title: 'M&Aナビ正式版公開のお知らせ',
    description:
      'この度、株式会社ALIVALは誰もが低コストでM&Aを行うことができる「M&Aナビ（エムエーナビ）」の正式版を公開いたしました。M&Aナビは、企業の売却または買収を希望される経営者の方々や、他社のM&A業務を支援する士業やコンサルタントの方など、M&Aに関わるさまざまな方にご利用いただけるオンラインプラットフォームです。6月のβ版公開以降、約1,000社の登録をいただいており、既に複数の成約実績を上げています。その間、ユーザーの皆さまから多くの機能改善要望やM&A実務における課題などをお聞きすることができました。今後も継続して、それらのご期待を順次M&Aナビに反映させてまいります。',
  },
  notice2: {
    title: '【50社限定】無料株価算定キャンペーン開始のお知らせ',
    description:
      '株式会社ALIVAL（東京都港区、代表取締役 西村将明）は、M&Aにおける候補先マッチングから成約までを一貫してオンライン上でサポートするプラットフォーム「M&Aナビ」β版の公開を記念して、50社限定で自社の株価を無料で算定するキャンペーンを開始いたしました。g自社の適正な株価を知ることは、事業承継や出口戦略など会社の方向性を検討するにあたって大変重要です。まだ譲渡を具体的に考えていない段階でも現時点での評価額を知ることで、 将来に向かってより高く譲渡するための指針を得ることができます。gぜひこの機会に自社の適正な株価を知っていただき、貴社の今後を担う意思決定にお役立てください。',
  },
  notice1: {
    title: 'M&Aナビβ版の公開のお知らせ',
    description:
      'この度、株式会社ALIVALは企業経営に新たな選択肢を提供し、日本経済の発展に貢献するために「M&Aナビ（エムエーナビ）」β版を開設いたしました。M&Aナビは、譲渡ならびに譲受を希望される企業や、M&A業務を支援する士業やコンサルタントの方など、M&Aに関わるさまざまな方にご利用いただけるオンラインプラットフォームです。gM&Aナビは、M＆A実務を数多く行ってきたプロが開発いたしました。交渉の過程で発生する多くの業務や準備が、M&A成立へ向けた大きなハードルになっていることを痛感しておりましたが、M&Aナビはそういった業務をオンラインでナビゲートすることで、自分たちで成立に向けた交渉を行うこと',
  },
}
